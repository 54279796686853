<template>
<div class="layout">
<div class="layout-header"></div>
<div class="layout-banner"></div>
<div class="container1500 mh500">
<div class="layout-main-top"></div>
<div class="layout-main-middle">
<div class="layout-main-left">
<div class="list-box">
<div class="title">客户案例</div>
<div class="list-box-item">xx设备</div>
<div class="list-box-item">xx设备</div>
<div class="list-box-item">xx设备</div>
<div class="list-box-item">xx设备</div>
</div>
<div class="info-box">
<div class="title">联系我们</div>
<div class="info-box-item">
<strong>深圳市创环环保科技有限公司</strong>
</div>
<div class="info-box-item">
                            广东省深圳市龙岗龙城街道清林西路留学生创业园一园南区205室
                        </div>
<div class="info-box-item">邮编：518000</div>
<div class="info-box-item">
                            电话：0755-26519737 | 17727811780
                        </div>

</div>
</div>
<div class="layout-main-right"></div>
</div>
</div>
<nav-footer></nav-footer>
</div>
</template>
<script>
import NavFooter from "../../components/NavFooter.vue";
export default {
    name: "CustomerCase",
    components: {
        NavFooter,
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.layout {
    position: relative;
    width: 100%;
    z-index: 0;
    .layout-header {
        height: 100px;
    }
    .layout-banner {
        width: 100%;
        height: 350px;
        background: url("../../assets/img/banner3.jpg") no-repeat center;
        background-size: cover;
    }
    .mh500 {
        min-height: 500px;
        .layout-main-top {
            height: 40px;
            border-bottom: 1px solid #bcbcbc50;
        }
        .layout-main-middle {
            display: flex;
            min-height: 460px;
            padding: 40px 0;
            .layout-main-left {
                width: 305px;
                margin-right: 71px;
                .list-box {
                    width: 305px;
                    margin-bottom: 40px;
                    // min-height: ;
                    .title {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 20px;
                        font-size: 20px;
                        color: #fff;
                        background-color: #0e2686;
                        box-sizing: border-box;
                    }
                    .list-box-item {
                        width: 100%;
                        height: 38px;
                        line-height: 38px;
                        padding: 0 20px;
                        color: #657180;
                        font-size: 16px;
                        border: 1px solid rgba(236, 236, 236, 1);
                        border-top: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        &:hover {
                            color: #0e2686;
                        }
                    }
                }
                .info-box {
                    width: 305px;
                    border: 1px solid rgba(236, 236, 236, 1);
                    .title {
                        // width: 305px;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 20px;
                        font-size: 20px;
                        color: #fff;
                        background-color: #0e2686;
                        box-sizing: border-box;
                    }
                    .info-box-item {
                        // width: 100%;
                        // height: 38px;
                        line-height: 38px;
                        padding: 0 20px;
                        color: #657180;
                        font-size: 14px;
                        box-sizing: border-box;
                    }
                }
            }
            .layout-main-right {
                width: 1150px;
                background: #bcbcbc;
            }
        }
    }
}
</style>